import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import setupLogRocketReact from 'logrocket-react';
import configuration from 'constants/config';

type User = {
  _id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  userType?: string;
};

const shouldRunLogrocketTracking = (user?: User) => {
  const isGreenSite =
    window.location.host.startsWith('www') ||
    window.location.host.startsWith('joinfurther.com');

  return (
    configuration.STAGE === 'production' &&
    (!isGreenSite || (isGreenSite && user))
  );
};

const shouldRunSentryTracking = () => {
  return configuration.STAGE === 'production';
};

export const initLogrocket = (user?: User) => {
  if (shouldRunLogrocketTracking(user)) {
    LogRocket.init('sq3uml/investor-site', {
      network: {
        requestSanitizer: (request) => {
          if (request.url.toLowerCase().indexOf('login') !== -1) {
            request.body = null;
          }

          return request;
        },
      },
    });
    if (user) {
      LogRocket.identify(user._id, {
        name: `${user?.firstName} ${user?.lastName}`,
        email: user.email,
        userType: user?.userType,
      });
    }
    setupLogRocketReact(LogRocket);
  }
};

export const initSentry = () => {
  if (shouldRunSentryTracking()) {
    Sentry.init({
      dsn: 'https://0c60065c2f103c7980916c9819d4472b@o4504655840739328.ingest.us.sentry.io/4507017375449088',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
          networkDetailAllowUrls: [/^(?!.*login).*$/],
        }),
      ],
      tracesSampleRate: 0.1,
      replaysSessionSampleRate: 1,
      replaysOnErrorSampleRate: 1,
    });
  }
};

export const identifySentry = (user: User) => {
  if (shouldRunSentryTracking()) {
    Sentry.setUser({
      id: user._id,
      name: `${user?.firstName} ${user?.lastName}`,
      email: user.email,
      userType: user?.userType,
    });
  }
};
